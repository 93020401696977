import $ from "jquery";

import("swiper/scss");
import("swiper/scss/effect-fade");

(async () => {
  const { default: Swiper } = await import("swiper");
  const { Navigation, EffectFade } = await import("swiper/modules");
  $(".slider--type-2 .swiper").each(function () {
    new Swiper(this, {
      modules: [Navigation, EffectFade],
      effect: "fade",
      slidesPerView: 1,
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        prevEl: this.querySelector(".navigation--prev"),
        nextEl: this.querySelector(".navigation--next"),
      },
    });
  });
})();
